<template>
  <section class="tickets container-fluid align-center px-6 pt-6 pb-0">
      <!-- <div class="is-mobile width-100" > <h3>{{ $t('Tickets') }}</h3><div> -->
    <v-row :gutters="3" pa-2>
      <div class="ticket-overlay" @click="isVisible=!isVisible" v-show="isVisible"></div>
 
      <v-col  class="sidebar-container" >
        <div class="sidebar"  :class="isVisible ? 'visible':''" >
          <h3>{{ $t('Tickets') }}</h3>
    
          <v-list>
            <v-list-item-group v-model="filters.caseStatus" color="primary">
              <template v-for="(item, i) in status">
                <v-list-item
                  :value="item.id"
                  :key="`status-${i}`"
                  active-class="primary--text text--accent-4"
                  :class="filters.caseStatus == item.value? 'primary--text text--accent-4':''"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <svg-icon :icon="item.icon"></svg-icon>
                  </v-list-item-icon>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
          <v-menu
            ref="menu"
            v-model="datepicker_view"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="computedDateFormattedMomentjs"
                :label="$t('Select date range')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                clearable
                @click:clear="filters.dates = []"
                class="font-12"
              ></v-text-field>
            </template>
            <v-date-picker v-model="filters.dates" no-title scrollable range>
            </v-date-picker>
          </v-menu>
          <div v-if="userProfile == 'MANAGER' && getProjects && getProjects[1]">
            <h4>{{ $t('Projects') }}</h4>
            <v-list>
              <v-list-item-group v-model="filters.projectId" multiple>
                <template v-for="(item, i) in getProjects">
                  <v-list-item
                    :key="`item-${i}`"
                    :value="item.record_id"
                    active-class="primary--text text--accent-4"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.project_name"
                        ></v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-checkbox
                          :input-value="active"
                          color="primary accent-4"
                        ></v-checkbox>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </div>
          <div v-if="userProfile == 'MANAGER'">
            <h4>{{ $t('Priority') }}</h4>
            <v-list>
              <v-list-item-group v-model="filters.priority" multiple>
                <template v-for="(item, i) in priority">
                  <v-list-item v-if="!item.hide"
                    :key="`item-${i}`"
                    :value="item.value" 
                    active-class="primary--text text--accent-4"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.name"
                        ></v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-checkbox
                          :input-value="active"
                          color="primary accent-4"
                        ></v-checkbox>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </div>
          <div v-if="userProfile == 'MANAGER'">
            <h4>{{ $t('Brand') }}</h4>
            <v-list>
              <v-list-item-group v-model="filters.brand" multiple>
                <template v-for="(item, i) in brands">
                  <v-list-item 
                    :key="`item-${i}`"
                    :value="item.id" 
                    active-class="primary--text text--accent-4"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.label"
                        ></v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-checkbox
                          :input-value="active"
                          color="primary accent-4"
                        ></v-checkbox>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </div>
          <h4>{{ $t('Categories') }}</h4>

          <div v-if="getCategories && getCategories[0]">
            <v-treeview
              v-model="filters.category"
              selectable
              item-disabled="locked"
              :items="
                groupCategories(
                  getCategories,
                  'parent_category',
                  'category_name',
                  'record_id'
                )
              "
            ></v-treeview>

            <!-- <v-list-item-group v-model="filters.category" multiple>
              <template v-for="(item, i) in getCategories">
                <v-list-item
                  :key="`item-${i}`"
                  :value="item"
                  active-class="primary--text text--accent-4"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="item.category_name"
                      ></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox
                        :input-value="active"
                        color="primary accent-4"
                      ></v-checkbox>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template> </v-list-item-group -->
          </div>
        </div>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>
             
              <v-btn
              color="primary"
               @click="isVisible=!isVisible"
              depressed
              large
             
              class="btn-mobile"
            > FILTER
            </v-btn>
            <div   style=" margin-left: 10px; margin-top:-12px;">
            <v-text-field
              v-model="searchKeyword"
              append-icon="mdi-magnify"
              :label="$t('Search')"
              single-line
              clearable
              hide-details
              @click:clear="searchKeyword='';filters.keyword='';"
            ></v-text-field>
            </div>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :to="{ name: 'createTicket' }"
              depressed
              large 
               style="   padding-top: 15px; "
              class="btn-desktop btn-mini-size"
            >{{ $t('Create Ticket') }}
            </v-btn>
            <v-btn
              color="primary"
              :to="{ name: 'createTicket' }"
              depressed
              large
              style="   padding-top: 15px; "
              class="btn-mobile btn-mini-size"
            > <i class="fal fa-lg fa-plus"></i>
            </v-btn>
            <v-btn
              color="success"
               v-if="userProfile == 'MANAGER'"
               :href="url"
              depressed
              large
              class="btn-mini-size"
            > <i class="fal fa-lg fa-file-excel"></i>
            </v-btn>
             <!-- <a
              color="success"
            
              depressed
              large
              style="margin-top: -14px; min-width: 44px;margin-left: 10px;padding-left:5px;padding-right:5px;     height: 44px;"
            > <i class="fal fa-lg fa-file-excel"></i>
            </a> -->
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="tickets"
            class="elevation-1 tickets-table"
            :items-per-page.sync="filters.rowsPerPage"
            :server-items-length="total"
            :page.sync="filters.page"
            :sort-by.sync="filters.sort.name"
            :loading="loading"
            :sort-desc.sync="filters.sort.desc"
            :footer-props="{
              'items-per-page-options': [25, 50, 100],
            }"
          >
            <template v-slot:item.record_id="{ item }">
              <div class="inline-col">
                <v-menu
                  v-model="item.threadOptionVisibility"
                  :close-on-content-click="false"
                  :nudge-width="200"
                  offset-x
                >
                  <template v-slot:activator="{ on, attrs }">
                    <a v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </a>
                  </template>

                  <v-card>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>{{
                              $t('Settings')
                            }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>

                    <v-divider></v-divider>

                    <v-list>
                      <v-list-item>
                        <v-list-item-action>
                          <v-select
                            :label="$t('Priority')"
                            :items="priorities"
                            outlined
                            item-text="name"
                            item-value="value"
                            :menu-props="{
                              zIndex: 101,
                            }"
                            v-model="item.priority"
                            @change="updatePriority(item)"
                            dense
                            flat
                            hide-details
                          />
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn text @click="item.threadOptionVisibility = false">
                        {{ $t('Cancel') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
                <router-link
                  :to="{ name: 'ticketDetail', params: { id: item.record_id } }"
                >
                  {{ item.record_id }}
                </router-link>
              </div>
            </template>
            <template v-slot:item.subject="{ item }">
              <div class="subject-field">
                <span
                >{{ item.sender_name }}
                  {{
                    item.sender_email ? `&lt;${item.sender_email}&gt;` : ''
                  }}</span
                >
                <router-link
                  :to="{ name: 'ticketDetail', params: { id: item.record_id } }"
                >
                  {{ item.subject }}
                </router-link>
              </div>
            </template>
            <template v-slot:item.priority="{ item }">
              <div v-if="item.priority">
                <i :title="item.cfd_priority_label"
                   :style="{ color: '#'+item.cfd_priority_color }"
                   :class="'fal ' + item.cfd_priority_value"
                ></i>
              </div>
            </template>
            <template v-slot:item.case_status="{ item }">
              <v-chip :color="'#' + item.cfd_case_status_color + opacity" dark>
                {{
                  filters.caseStatus == 2 && item.cfd_hold_reason_label ? item.cfd_hold_reason_label : item.cfd_case_status_label
                }}
              </v-chip>
            </template>
            <template v-slot:item.created_on="{ item }">
              {{ setTime(item.created_on) }}
            </template>

            <template v-slot:item.last_message_date="{ item }">
              {{
                item.last_message_date
                  ? setTime(item.last_message_date)
                  : 'Not Updated'
              }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import moment from 'moment'
import { ThreadsProxy } from '@/proxies/ThreadsProxy'
import { mapGetters } from 'vuex'
import SvgIcon from '@/components/common/kvSvgIcon.vue'
import { ChangePriority } from '@/proxies/ChangePriorityProxy'
import Codox from '@/components/Codox'

export default {
  name: 'Tickets',
  components: {
    SvgIcon,
    Codox
  },
  data () {
    return {
      isVisible:false,
      priorities: [
        {
          value: 1,
          name: this.$t('Urgent'),
          icon: 'fa-siren-on'
        },
        {
          value: 2,
          name: this.$t('High'),
          icon: 'fa-star-exclamation'
        },
        {
          value: 3,
          name: this.$t('Medium'),
          icon: ''
        },
        {
          value: 4,
          name: this.$t('Low'),
          icon: ''
        },
      ],
      headers: [
        {
          text: '#',
          align: 'end',
          value: 'record_id',
          width: 95,
        },
        {
          text: this.$t('Subject'),
          align: 'start',
          value: 'subject',
        },
        {
          text: '',
          align: 'start',
          value: 'priority',
          width: 35,
        },
        {
          text: this.$t('Status'),
          value: 'case_status',
          width: 150,
        },
        // {
        //   text: 'Label',
        //   value: 'cfd_case_status_label',
        //   sortable: false
        // },
        {
          text: this.$t('Created'),
          value: 'created_on',
          width: 130,
        },
        {
          text: this.$t('Last Update'),
          value: 'last_message_date',
          width: 130,
        },
      ],

      status: [
        {
          id: '1',
          name: this.$t('Open'),
          value: [1, 5],
          icon: 'fal fa-inbox',
        },
        {
          id: '2',
          name: this.$t('My'),
          value: -1,
          icon: 'fal fa-inbox-in',
        },
        {
          id: '3',

          name: this.$t('Waiting'),
          value: 2,
          icon: 'fal fa-comment-alt-exclamation',
        },
        {
          id: '4',
          name: this.$t('Closed'),
          value: 3,
          icon: 'fal fa-comment-alt-check',
        },
      ],

      priority: [
        {
          name: this.$t('Urgent'),
          value: 1,
          icon: 'mdi-inbox',
        },
        {
          name: this.$t('High'),
          value: 2,
          icon: 'mdi-domain',
        },
        {
          name: this.$t('Medium'),
          value: 3,
          icon: 'mdi-timer',
          hide:true
        },
        {
          name: this.$t('Low'),
          value: 4,
          icon: 'mdi-check',
          hide:true

        },
      ],
 
      total: 0,
      searchKeyword: '',
      lastKeywordTime: null,
      keywordTimer: null,
      loading: false,
      datepicker_view: false,
      tickets: [],
      url:'',
      filename:'',
      dates: [],
      projects: [],
      opacity: '77',
    }
  },
  methods: {
    handleFocusOut(){
      // window.open(this.url);
      this.isVisible = false;
    },
    downloadFile(){
      window.open(this.url);
    },
    updatePriority (item) {
      let pr = this.priorities.find((k) => k.value == item.priority)
      new ChangePriority()
        .create({
          item: {
            priority: item.priority,
            threadId: item.record_id,
          },
        })
        .then(() => {
          item.threadOptionVisibility = false;
          (item.cfd_priority_label = pr.label),
            (item.cfd_priority_color = pr.color)
        })
    },
    groupCategories (cats, rel_field, name_field, id_field) {
      let res = []
      cats.forEach((k) => {
        res.push({
          ...k,
          locked: false,
          children: [],
          id: k[id_field],
          name: k[name_field],
        })
      })
      res.forEach((k) => {
        if (k[rel_field]) {
          let found = res.find((a) => a.record_id == k[rel_field])
          found.children.push(k)
        }
      })

      let ct = []
      res.forEach((k) => {
        if (k[rel_field] === null) {
          ct.push(k)
        }
      })
      return ct
    },
    setTime (date) {
      return moment(date).format('DD/MM/YYYY HH:mm')
    },
    getTickets () {
      this.loading = true
      let parameters = {
        request_type:[1,null,0,'']
      }
      parameters.page = this.filters.page
      parameters.sort = this.filters.sort
      parameters.dates = this.filters.dates
      parameters.rowsPerPage = this.filters.rowsPerPage
      parameters.keyword = this.filters.keyword
      parameters.projectId = this.filters.projectId
      parameters.priority = this.filters.priority

      if (this.filters.caseStatus && this.filters.caseStatus) {
        parameters.caseStatus = this.status.find((k) => {
          return k.id == this.filters.caseStatus
        })
        parameters.caseStatus = parameters.caseStatus ? parameters.caseStatus.value : ''
      }

      if (this.filters.brand && this.filters.brand) {
        parameters.brand = this.filters.brand 
      }

      parameters.categoryId = this.filters.category

      let pm = {...parameters};
      pm.forExport = 1

      new ThreadsProxy()
        .setParameters(parameters)
        .all()
        .then((response) => {
          this.loading = false
          response.data = response.data.map((a) => {
            let mn = { ...a }
            mn.threadOptionVisibility = false
            return mn
          })
          this.tickets = response.data
          this.total = response.totalCount
        })

      new ThreadsProxy()
        .setParameters(pm)
        .all()
        .then((response) => {
          this.loading = false 
          this.url = response.url;
          this.filename = response.filename;
        })
    },
    getColor (calories) {
      if (calories > 3) {
        return 'red'
      } else if (calories > 2) {
        return 'orange'
      } else {
        return 'green'
      }
    },
  },
  computed: {
    ...mapGetters('common', ['getCategories']),
    ...mapGetters('common', ['getProjects']),
    ...mapGetters('account', ['userProfile']),
    filters:{
      get(){
        return this.$store.state.filters.tickets;
      },
      set(val){
           this.$store.state.filters.tickets = val;
      }
    },
    computedDateFormattedMomentjs () {
      return this.filters.dates ? moment(this.filters.dates[0]).format('DD/MM/YY') + ' ~ ' + moment(this.filters.dates[1]).format('DD/MM/YY') : []
    },
     mainData() {
      return this.$store.state.common.main;
    },
    brands(){
      return this.mainData.assigned_brands
    }
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      handler: 'getTickets',
    },
    isVisible(){
      this.$refs.sidebar.focus();
    },
    searchKeyword (val) {
      if ((val && val.length > 2) || val == '') {
        var nowMS = new Date()
        if (!this.lastKeywordTime) {
          this.lastKeywordTime = new Date()
        }
        let diff = nowMS.getTime() - this.lastKeywordTime.getTime()

        if (diff < 500 && this.keywordTimer) {
          clearTimeout(this.keywordTimer)
        }
        this.keywordTimer = setTimeout(() => {
          this.filters.keyword = val
        }, 350)

        this.lastKeywordTime = nowMS
      } else if (val == '') {
        this.filters.keyword = ''
        this.getTickets()
      }
    },
  },
}
</script>


<style lang="scss" scoped>
 


.sidebar {
  background: #ebeef2;
  height: calc(100vh - 110px);
  overflow: auto;
  padding-left: 16px;
  padding-right: 16px;
  position: fixed;
  width: 270px;

  h3 {
    color: #465669;
    font-size: 18px;
    font-weight: 600;
    line-height: 3;
  }

  h4 {
    color: #465669;
    font-size: 14px;
    font-weight: 600;

    line-height: 2;
  }

  .v-list.theme--light {
    background: none;
  }

  .v-list {
    // max-height: 250px;
    // overflow: auto;
  }

  .v-list-item {
    flex-direction: row-reverse;
    height: 30px;
    min-height: 30px;
    padding: 0px;
  }

  .v-list-item__action {
    margin-left: 0 !important;
    margin: 2px 16px 2px 0px;
  }

  .v-list-item__title {
    align-self: center;
    font-size: 14px;
    color: #465669;
  }

  .v-list-item__icon {
    align-self: flex-start;
    margin: 7px 10px 0px 6px !important;
  }
}
 
.subject-field {
  span {
    display: block;
    width: 100%;
    color: #868686;
    font-size: 14px;
  }
}
</style>
<style>
.v-text-field--outlined fieldset {
  background: #fff !important;
}

.document_name .v-image {
  margin-right: 10px;
}

.document_name {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-flex;
  align-items: center;
}

.search-box {
  padding: 10px 0;
}

.tickets-table .v-data-table__wrapper {
  min-height: calc(100vh - 285px);
}

.v-treeview-node__root {
  display: flex;
  align-items: center;
  min-height: 35px;
  padding-left: 0px;
  padding-right: 3px;
  position: relative;
  font-size: 12px;
  margin-left: -10px;
}

.v-application--is-ltr .v-treeview-node__checkbox {
  margin-left: 0px;
}

.v-treeview-node__level {
  width: 18px;
}

.inline-col {
  width: 100%;
  display: inline-flex;
  align-items: baseline;
  justify-content: space-between;
}

.font-12 input {
  font-size: 13px;
}
.v-application .success {
    background-color: #1D6F42 !important;
    font-size: 16px;
}

</style>
